<template>
    <v-app>
        <!-- <v-app-bar app color="primary" dark flat>
            <v-toolbar-title class="d-flex"><img alt="Vue logo" src="@/assets/logo.png" height="30"></v-toolbar-title>
        </v-app-bar> -->
        <v-main>
            <router-view></router-view>
        </v-main>
    </v-app>
</template>
<script>
export default {
    name: 'App',
    components: {},

    data: () => ({
        //
    }),
};
</script>