import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '@/views/register.vue';
import Install from '@/views/install.vue';
import BilendiInstall from '@/views/bilendiInstall.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/install',
        name: 'install',
        component: Install,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/bilendi-install',
        name: 'BilendiInstall',
        component: BilendiInstall,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
