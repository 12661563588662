<template>
    <v-container class="fill-height">
    </v-container>
</template>
<script>
export default {
    name: 'OnboardingForm',
    data: () => ({}),
    mounted(){
        localStorage.IDKantar = this.$route.query.ID;
        window.location.href = "https://chrome.google.com/webstore/detail/alfie/domjdfjalbobafmoocjppdhmhkombooi"
    }
}
</script>