<template>
    <v-form v-if="!success" ref="form" v-model="valid">
        <v-container class="fill-height">
            <v-row class="text-center" align="center">
                <v-col offset="2" cols="8">
                    <h2>Installation et validation d'Alfie</h2>
                </v-col>
                <v-col offset-md="4" md="4" offset="2" cols="8">
                    <v-text-field v-model="email" label="Votre email*" required :rules="emailRules"></v-text-field>
                </v-col>
                <v-col offset-md="4" md="4" offset="2" cols="8">
                    <v-radio-group v-model="sex" hide-details>
                        <template v-slot:label>
                            <div><b>Êtes vous ...</b></div>
                        </template>
                        <div class="mt-3">
                            <v-radio value="male">
                                <template v-slot:label>
                                    <div>un Homme</div>
                                </template>
                            </v-radio>
                            <v-radio value="female">
                                <template v-slot:label>
                                    <div>une Femme</div>
                                </template>
                            </v-radio>
                            <v-radio value="na">
                                <template v-slot:label>
                                    <div>Je préfère ne pas le dire</div>
                                </template>
                            </v-radio>
                        </div>
                    </v-radio-group>
                </v-col>
                <v-col offset-md="4" md="4" offset="2" cols="8">
                    <v-text-field v-model="age" type="number" label="Votre age (non obligatoire)" :rules="ageRules"></v-text-field>
                </v-col>
                 <v-col offset-md="4" md="4" offset="2" cols="8">
                    <v-text-field v-model="IR" label="Code personnel (non obligatoire)" :rules="IRRules"></v-text-field>
                </v-col>

                <v-col offset-md="4" md="4" offset="2" cols="8" class="mt-4">
                    <p class="grey--text text--darken-1">Nous collectons vos données de navigation pour réaliser des études anonymes. Pour plus d'informations sur notre politique de confidentialité, cliquez <a href="https://www.alfie-app.com/confidentialite" target="_blank">ici</a>. Acceptez-vous de partager vos données de navigation ?</p>
                    <v-checkbox v-model="cgu" label="J'accepte les CGU" class="mt-0" hide-details :rules="checkRules"></v-checkbox>
                    <v-checkbox v-model="tracking" label="J'accepte le tracking de mes données" :rules="checkRules"></v-checkbox>
                </v-col>
                <v-col offset="2" cols="8" class="mt-8">
                    <v-btn depressed color="primary" x-large :disabled="!valid" @click="installExt">
                        Rejoindre le programme
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
    <v-container v-else class="fill-height">
        <v-row class="text-center" align="center">
            <v-col cols="12">
                <v-icon x-large class="" color="success">mdi-check</v-icon>
            </v-col>
            <v-col cols="12">
                <h2>Merci pour votre inscription !</h2>
            </v-col>
            <v-col cols="12">
                <p>Vous pouvez commencer à profiter des avantages d'Alfie en faisant vos achats en Drive !</p>
            </v-col>
            <v-col cols="12">
                <v-btn depressed color="primary" @click="leclercRedirect" x-large>
                    Commencer vos courses
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'Register',

    data: () => ({
        idChromeExt: process.env.NODE_ENV == "production" ? "domjdfjalbobafmoocjppdhmhkombooi" : "gjfjmmpikbijnlfcfhjlhibfedbhdjbl",
        valid: true,
        success: false,
        email: "",
        sex: "na",
        age: null,
        IR: localStorage.getItem('mingleCode') || "",
        IRRules: [
            v => /^\w{32}$/.test(v) || !v || 'Votre code personnel doit être constitué de 32 caractères alphanumériques',
        ],
        ageRules: [
            v => ((v >= 18 && v < 100) || !v) || "Vous devez avoir entre 18 et 100 ans"
        ],
        emailRules: [
            v => !!v || "Votre email est requis",
            v => /.+@.+\..+/.test(v) || 'L\'email doit être valide',
        ],
        checkRules: [v => !!v || 'Vous devez acceptez pour continuer'],
        cgu: false,
        tracking: false,
    }),
    methods: {
        async installExt() {
            try {
                // console.log("process.env.NODE_ENV: ", process.env.NODE_ENV)
                // console.log("this.idChromeExt: ", this.idChromeExt)
                this.$refs.form.validate()
                chrome.runtime.sendMessage(this.idChromeExt, { email: this.email, sex: this.sex, age: this.age, IR: this.IR }, resp => {
                    // console.log("resp: ", resp)
                    if (!resp.success) {
                        // Extension is not installed.
                        console.log("Not installed")
                        return;
                    }
                    if (resp.success) {
                        this.success = true
                        if (this.IR !== ""){
                            window.open("https://surveyd.bilendi.com/survey/selfserve/53b/g001/231250?list=1&state=" + localStorage.getItem('BilendiState'), '_blank').focus();
                            // window.open("https://mingle.respondi.com/s/1683492/ospe.php?return_tic=" + this.IR, '_blank').focus();
                        }
                    }
                });
            } catch (e) {
                // Extension is not installed.
                console.log(e)
            }
        },
        async leclercRedirect() {
            window.location.replace("https://www.leclercdrive.fr/")
        }
    }
}
</script>
