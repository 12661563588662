<template>
    <v-container class="fill-height">
    </v-container>
</template>
<script>
export default {
    name: 'BilendiInstall',
    data: () => ({}),
    mounted(){
        localStorage.BilendiState = this.$route.query.state;
        localStorage.mingleCode = this.$route.query.perso3;
        window.location.href = "https://chrome.google.com/webstore/detail/alfie/domjdfjalbobafmoocjppdhmhkombooi"
    }
}
</script>